/* #Site Styles
================================================== */

/*@import url(//fonts.googleapis.com/css?family=Roboto:400,300,500);*/

@import url("https://fonts.googleapis.com/css2?family=Puritan:wght@400;700&display=swap");

/* ### general ### */

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  font-family: Verdana, Geneva, sans-serif;
  /* font-family: "Gill Sans"; */
  font-size: 16px;
  color: #000;
}

input,
select,
textarea {
  font-size: 12px;
  color: #757575;
}

input[type="date"] {
  /* border: none; */
  box-sizing: border-box;
  outline: 0;
  padding: 0.75rem;
  position: relative;
  width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

img {
  border: 0px;
  display: block;
}

ul,
li {
  list-style: none;
}

:focus {
  outline: none;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: #00a1ff;
  transition: 0.3s;
}

.floatL {
  float: left !important;
}

.floatR {
  float: right !important;
}

.clear {
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

button {
  cursor: pointer;
}

.centerCol {
  margin: 0 auto;
  padding: 0 10px;
  width: 100%;
  max-width: 1160px;
  box-sizing: border-box;
  position: relative;
}

#people_wrapper {
  position: relative;
  display: block;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
}

#mainCntr {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.centerBox {
  width: 100%;
  padding: 0px;
  margin: 0 auto;
}  

.green{
  color: green;
  display: inline;
}
.kkktt{ 
  display: inline;
}

.bgblack1{ 
  color: #000;
}

.headercol {
  width: 100%;
  float: left;
  box-sizing: border-box;
  background: #fff;
}

.headercol .logo {
  width: 139px;
  float: left;
  margin: 18px 0;
}

.headercol .logo img {
  width: 100%;
}

.headercol .email {
  padding-top: 40px;
  float: right;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
}

.headercol .email a {
  color: #124e75;
  padding-right: 30px;
  background: url(./images/email_icon.png) center right no-repeat;
}

.headercol a:hover {
  color: #000;
}

/* #naviBox ========= */
.naviBox {
  width: 100%;
  float: left;
  height: 30px;
  padding: 0;
  position: relative;
  background: #124e75;
}

.naviBox ul {
  list-style: none;
  float: left;
  padding: 0 0;
}

.naviBox li {
  padding: 0 20px;
  float: left;
  font-size: 14px;
  position: relative;
}

.naviBox li a {
  text-decoration: none;
  line-height: 30px;
  color: #fff;
  z-index: 55;
  font-weight: 500;
  position: relative;
  transition: 0.1s;
  transform: translate(0, 50px);
}

.naviBox li::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 30px;
  background: #ffa800;
  transition: width 0.3s;
}

.naviBox li:hover::after {
  width: 100%;
  color: #000;
}

.naviBox li a:hover {
  color: #000;
}

.naviBox .linkbar {
  display: none;
  width: 32px;
  height: 32px;
  float: right;
  margin-left: 15px;
  text-indent: -999px;
  background: #00327a url(./images/menu.svg) center center no-repeat;
}

.naviBox .linkbar.fixed {
  background: #00327a url(./images/close.png) center center no-repeat;
}

.bannerBox {
  width: 100%;
  float: left;
  position: relative;
  position: relative;
}

.bannerBox .banner {
  position: absolute;
  width: 54%;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.6);
}

.bannerBox h2 {
  padding: 5% 0 0 0;
  font-size: 82px;
  color: #00327a;
  text-align: center;
}

.bannerBox h2 span {
  font-size: 44px;
  padding-bottom: 0px;
  color: #614c67;
  display: block;
}

.bannerBox h2 .text {
  font-size: 61px;
  display: block;
}

.bannerBox p {
  font-size: 28px;
  display: inline-block;
  text-align: right;
  line-height: 28px;
  color: #00327a;
  font-weight: 800;
  font-family: "Verdana";
}

.bannerBox p span {
  font-weight: 400;
}

.bannerBox .bottom {
  padding: 11% 9% 0 8%;
  float: left;
  width: 100%;
}

.bannerBox img {
  width: 100%;
}

.bannerBox .bottom a {
  padding: 0 40px 0 20px;
  margin-left: 2%;
  position: relative;
  float: right;
  line-height: 30px;
  display: inline-block;
  border-radius: 20px;
  font-size: 14px;
  color: #000;
  background: #ffa800;
}

.bannerBox .bottom a:hover {
  background: #000;
  color: #fff;
}

.bannerBox .bottom a::before {
  content: "";
  position: absolute;
  right: 20px;
  top: 11px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 8px solid #000;
}

.bannerBox .bottom a:hover::before {
  border-left: 8px solid #fff;
}

.welcomeText {
  width: 100%;
  float: left;
  margin: 30px 0 0 0;
  display: block;
}

.welcomeText h2 {
  font-size: 28px;
  color: #00327a;
  margin-bottom: 20px;
}

.welcomeText p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 15px;
}

.welcomeText {
}

.welcomeText {
}

.bannerCntr {
  padding: 20px 0;
  width: 100%;
  float: left;
  position: relative;
  position: relative;

  background: #124e75;
}

.bannerCntr ul {
  list-style: none;
  margin: 0 -11px;
}

.bannerCntr li {
  padding: 0 11px;
  width: 33.3%;
  float: left;
}

.bannerCntr p {
  padding: 5px 14%;
  border-radius: 50px;
  font-size: 24px;
  color: #124e75;
  background: #ffa800;
  text-align: center;
}

.listBox {
  width: 100%;
  float: left;
  margin: 20px 0;
}

.listBox .listcntr {
  margin: 0 -9px;
}

.listBox .list {
  padding: 0 9px;
  float: left;
  width: 25%;
}

.listBox .inner {
  padding: 8px;
  border: 1px solid #124e75;
  min-height: 532px;
  position: relative;
}

.listBox h3 {
  font-size: 24px;
  color: #0e3f85;
  margin-bottom: 10px;
  font-weight: 500;
  font-family: "Segoe UI Symbol";
}

.listBox ul {
  list-style: none;
  margin-bottom: 10px;
}

.listBox li {
  padding-left: 20px;
  list-style: none;
  position: relative;
  font-size: 16px;
  line-height: 26px;
  color: #000;
}

.listBox p {
  font-size: 16px;
  padding-bottom: 12px;
  color: #000;
  font-weight: bold;
}

.listBox li::after {
  content: "";
  display: block;
  position: absolute;
  top: 10px;
  left: 2px;
  width: 6px;
  height: 5px;
  background: #000;
  transition: width 0.3s;
}

.listBox .listimg {
  padding-bottom: 20px;
}

.listBox .listimg img {
  width: 100%;
}

.listBox .read {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 14px;
}

.listBox .read a {
  padding: 5px 30px 5px 15px;
  border-radius: 30px;
  font-size: 14px;
  position: relative;
  color: #fff;
  display: inline-block;
  text-align: center;
  background: #124e75;
  font-family: "Segoe UI Symbol";
}

.listBox a::after {
  content: "";
  position: absolute;
  right: 14px;
  top: 8px;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #fff;
}

.listBox a:hover {
  background: #ffa800;
}

.listBox ul.pass li {
  padding-bottom: 10px;
}

.listBox h4 {
  padding-right: 10px;
  margin-bottom: 20px;
  font-size: 26px;
  line-height: 44px;
  color: #000;
  font-weight: 400;
}

.triangle-right {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid green;
}

.contener {
  width: 100%;
  display: inline-block;
  background: #eeeeee;
}

.tophadding {
  padding: 50px 0;
  width: 100%;
  display: inline-block;
  text-align: center;
}

.tophadding h4 {
  padding-left: 183px;
  line-height: 70px;
  text-align: center;
  font-size: 52px;
  font-weight: bold;
  color: #376b8d;
  display: inline-block;
  background: url(./images/logo-evisa.jpg) left 11px no-repeat;
}

.navform {
  width: 100%;
  display: table;
  position: relative;
}

.navform li {
  padding-bottom: 5px;
  display: inline-block;
  float: left;
  width: 25%;
  text-align: center;
}

.navform .btn-circle {
  width: 45px;
  margin: auto;
  height: 45px;
  font-size: 23px;
  line-height: 45px;
  border-radius: 50px;
  color: #fff;
  background: #cdcdcc;
}

.navform p {
  margin: 10px 0;
  font-weight: bold;
  font-size: 15px;
  color: #4b6a86;
  text-align: center;
}

.navform li.active .btn-circle {
  background: #9f9128;
}

.navform li.active {
  border-bottom: 3px solid #cbcbcb;
}

.hedinginfo {
  padding: 0 0 0 15px;
  font-size: 20px;
  color: #fff;
  background: #396b8e;
  line-height: 46px;
}

.hedinginfo.mar {
  margin: 0 -20px;
  margin-bottom: 20px;
}

.colam50 {
  width: 50%;
  padding: 0 20px;
  float: left;
}

.btn-input {
  height: 30px;
  padding: 0 15px;
  line-height: 18px;
  font-size: 14px;
  color: #fff;
  border-radius: 0px;
  box-shadow: 1px 1px 1px 1px rgb(57 107 140);
  margin: 0px;
  border: 1px solid #2e6da4;
  background: #337ab7;
}

.frameBox {
  width: 100%;
  display: inline-block;
  padding-bottom: 18px;
}

.frameBox.mh {
  min-height: 285px;
}

.frameBox p {
  padding-left: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #000;
}

.frameBox p::after {
  content: "*";
  color: red;
  display: inline-flex;
  clear: both;
}

.frameBox .passport {
  width: 62%;
  float: left;
}

.frameBox .passport img {
  width: 100%;
}

.frameBox .profile {
  float: right;
  width: 31.5%;
}

.frameBox img {
  width: 100%;
  height: 100%;
  object-fit: fit;
}

.frameBox .profile .w-100 {
  border: 1px solid #000;
  width: 100%;
}
.frameBox .infoimg {
  background: #fff;
  margin: 0 25px;
  padding: 8px 25px 20px 20px;
}

.frameBox .photo {
  width: 130px;
  border: 4px solid #1e65ac;
}

.frameBox .photoinfo {
  width: 160px;
  border: 1px solid #000;
}

.frameBox .infoimg .text {
  padding: 5px;
  color: rgb(57, 107, 140);
}

.frameBox .infoimg p::after {
  content: "";
  color: red;
  display: inline-flex;
  clear: both;
}

.formheding {
  font-size: 14px;
  margin-bottom: 20px;
  color: #000;
  text-decoration: underline;
  font-weight: 400;
}

.frameBox .formheding {
  margin-bottom: 5px;
}

.fa-check {
  font-size: 15px;
  color: #4caf50;
  margin: 2px;
}

.totalproce {
  padding: 10px 25px;
  width: 100%;
}

.totalproce p {
  font-size: 18px;
  font-weight: bold;
}

.totalproce p span {
  font-size: 23px;
}

.btncolam {
  width: 100%;
  display: inline-block;
  padding: 20px 0px 0 0;
}

.btncolam .btn-input {
  float: right;
  text-align: center;
  min-width: 80px;
  display: inline-block;
  line-height: 30px;
  margin: 0 5px;
}

.red-btn {
  background: #d8544e;
  border-color: #d8544e;
}

.bn {
  background: none !important;
  margin: 0 !important;
  padding-left: 0 !important;
}

.input-label {
  padding-top: 7px;
  font-size: 13px;
  display: inline-block;
  max-width: 100%;
  font-weight: 700;
  margin-bottom: 0;
  text-align: left !important;
}

.inputdiv {
  width: 100%;
  float: left;
  margin-bottom: 20px;
}

.input-field {
  height: 30px;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.inputdiv.star .input-label::after {
  content: "*";
  color: red;
  display: inline-flex;
  clear: both;
}

.rightform {
  margin-top: -7px;
}

.flex {
  display: flex;
}

.flex select,
.flex .input-field {
  margin-right: 5px;
}

.flex select:last-child,
.flex .input-field:last-child {
  margin-right: 0px;
}

.textarea_fix {
  max-width: 100%;
  min-width: 100%;
  height: 100px !important;
}

.rightform .inputdiv {
  margin-bottom: 21px;
}

.rightside {
  padding-bottom: 20px;
  border-left: 2px solid #396b8e;
}

.rightside p {
  padding-left: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #000;
}

.rightside .btn-input {
  min-width: 115px;
  height: 28px;
  margin-left: 5px;
}

.needbox {
  width: 100%;
  text-align: center;
  display: inline-block;
  margin-bottom: 30px;
}

.needbox .btn-input {
  min-width: 110px;
  display: inline-block;
  line-height: 30px;
  margin: 0 5px;
  box-shadow: none;
  height: auto;
}

.needbox .btn-input:hover {
  background: #296396;
  border-color: #296396;  
  color: #fff;
}

.needbox .need {
  color: #396b8e;
}

.needbox .need:hover {
  color: #9e8e40;
}

.amountColam {
  padding: 25px 35px;
  width: 100%;
  background: #9e9129;
}

.amountColam h1 {
  font-size: 41px;
  color: #fff;
  font-weight: 400;
}

.amountColam h1 span {
  font-weight: bold;
}

.paymentBox {
  padding: 0 25px;
}

.paymentBox .payment {
  padding: 30px 9px;
  float: left;
  margin-right: 20px;
  margin-top: 10px;
  border-radius: 4px;
  box-shadow: -1px 1px 0px #cccccc;
  background-image: linear-gradient(to bottom, #ffffff, #e9e9e9);
}

.paymentBox li {
  float: left;
  margin: 0 9px;
  padding: 5px;
  border: 1px solid #999999;
  border-radius: 4px;
  max-width: 104px;
}

.paymentBox li img {
  width: 78px;
  height: 66px;
  max-height: 100px;
  object-fit: contain;
}

.paymentBox p {
  font-size: 15px;
  text-decoration: underline;
  color: #396d94;
  margin: 35px 0 0 0;
  font-weight: bold;
}

.modal {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
}

.modal.is-visible {
  display: block;
}

.modal-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 0%, 0.5);
  transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.modal.is-visible .modal-overlay {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.modal-wrapper {
  width: 52%;
  padding: 20px;
  transform: translate(-50%, -50%) scale(0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  border-top: 7px solid #48d586;
  box-shadow: 0px 2px 16px rgb(0 0 0 / 50%);
  border-radius: 5px;
  background: #fff;
  z-index: 9999;
}

.modal-transition {
  transform: translate(-50%, -50%) scale(1);
}

.modal.is-visible .modal-transition {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}

.modal-wrapper h3 {
  font-size: 22px;
  font-weight: 300;
  margin: 0px 0 20px 0;
}

.modal-wrapper p {
  font-size: 16px;
  line-height: 20px;
  color: #000;
  margin-bottom: 20px;
}

.modal-wrapper p span {
  font-size: 18px;
  font-weight: bold;
}

.modal-wrapper p span::after {
  content: "*";
  color: red;
  display: inline-flex;
  clear: both;
}

.modal-wrapper .container-checkbox {
  font-size: 16px;
}

.modal-wrapper .w-100 {
  text-align: center;
}

.modal-wrapper .btn-input {
  background: #5bb85d;
  border-color: #5bb85d;
  min-width: 120px;
  margin: 0 5px;
}

.modal-wrapper .red-btn {
  background: #d8544e;
  border-color: #d8544e;
}

.m-bottom_10 {
  margin-bottom: 10px;
}

.m-bottom_20 {
  margin-bottom: 20px;
}

.m-bottom_30 {
  margin-bottom: 30px;
}

.m-bottom_40 {
  margin-bottom: 40px;
}

.m-top_10 {
  margin-top: 10px;
}

.m-top_20 {
  margin-top: 20px;
}

.m-top_30 {
  margin-top: 30px;
}

.m-top_40 {
  margin-top: 40px;
}

.m-left_10 {
  margin-left: 10px;
}

.m-left_20 {
  margin-left: 20px;
}

.m-left_30 {
  margin-left: 30px;
}

.m-left_40 {
  margin-left: 40px;
}

.m-right_10 {
  margin-right: 10px;
}

.m-right_20 {
  margin-right: 20px;
}

.m-right_30 {
  margin-right: 30px;
}

.m-right_40 {
  margin-right: 40px;
}

.p-bottom_0 {
  padding-bottom: 0px !important;
}

.p-bottom_10 {
  padding-bottom: 10px;
}

.p-bottom_20 {
  padding-bottom: 20px;
}

.p-bottom_30 {
  padding-bottom: 30px;
}

.p-bottom_40 {
  padding-bottom: 40px;
}

.p-top_10 {
  padding-top: 10px;
}

.p-top_20 {
  padding-top: 20px;
}

.p-top_30 {
  padding-top: 30px;
}

.p-top_40 {
  padding-top: 40px;
}

.p-left_10 {
  padding-left: 10px;
}

.p-left_20 {
  padding-left: 20px;
}

.p-left_30 {
  padding-left: 30px;
}

.p-left_40 {
  padding-left: 40px;
}

.p-right_10 {
  padding-right: 10px;
}

.p-right_20 {
  padding-right: 20px;
}

.p-right_30 {
  padding-right: 30px;
}

.p-right_40 {
  padding-right: 40px;
}

.p5 {
  padding: 5px;
}

.brn {
  border: none !important;
  padding-top: 44lpx;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-20 {
  width: 20%;
}

.w-40 {
  width: 40%;
}

.w-70 {
  width: 70%;
}

.w-25 {
  width: 25%;
}

.w-33 {
  width: 33.3%;
}

.w-60 {
  width: 60%;
}

/* Hide the browser's default checkbox */
.container-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  line-height: 28px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.container-checkbox .checkmark {
  position: absolute;
  border: 1px solid #a8a8a8;
  border-radius: 3px;
  top: 0;
  left: 0;
  height: 27px;
  width: 27px;
  background-color: #fff;
}

.container-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.container-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.servicesBox {
  padding: 15px 0;
  width: 100%;
  float: left;
  background: #e9f2f9;
}

.servicesBox h4 {
  padding-bottom: 10px;
  font-size: 21px;
  font-weight: 400;
  color: #282828;
}

.servicesBox .leftside {
  width: 100%;

  padding-right: 85px;
}

.servicesBox .centerside {
  width: 20%;
  float: left;
}

.servicesBox .rightside {
  float: right;
  width: 21%;
  border: none;
  padding: 0;
}

.servicesBox p {
  font-size: 12px;
  line-height: 20px;
  color: #000;
}

.servicesBox ul {
  list-style: none;
}

.servicesBox li {
  font-size: 12px;
  line-height: 20px;
}

.servicesBox li a {
  color: #000;
  transition: 0.3s;
}

.servicesBox li a:hover {
  transition: 0.3s;
  color: #ffa800;
}

.footerBox {
  background: #ffa800;
  width: 100%;
  padding: 8px 0;
  float: left;
}

.footerBox .card {
  float: right;
}

.footerBox ul {
  list-style: none;
}

.footerBox li {
  padding-right: 15px;
  float: left;
}

.footerBox .copyright {
  color: #000;
  float: left;
  line-height: 30px;
  font-weight: 500;
  font-size: 12px;
  font-family: "Segoe UI Symbol";
}
.undertaking.col {
  margin: 15px;
  border: 1px solid silver;
  border-radius: 5px;
}

h5#undertaking_heading {
  text-align: center;
  padding: 8px;
  background-color: #3283cd;
  color: white;
}
.partial_form{
  background-color: #cecece;
  margin: 0 auto;
  border-radius: 20px;
  margin: 20px 0 ;
  overflow: hidden;
}
.partial_form button{
  display: inline-block;
  border: none;
  box-shadow: none;
  padding: 10px 20px;
  font-weight: 600;
}
.partial_form .hedinginfo{
  padding: 5px 10px ;
  text-align: center;
}
.partial_form .paymentBox{
  padding: 0;
}
.visa_page label {
  color: rgb(57 107 142);
  font-weight: 400;
}
.visa_page .btn-primary{
  background-color: #396b8e;
  border: none;
  border-radius: 0;
    width: 100%;
    font-size: 13px;
    padding: 4px 10px;
}
.visa_page .hedinginfo{
  font-size: 17px;
}

/* #Media Queries
================================================== */

@media only screen and (max-width: 1300px) {
  .bannerBox .bottom {
    padding: 3% 2% 0 3%;
  }

  .bannerBox h2 {
    font-size: 60px;
  }

  .bannerBox h2 span {
    font-size: 30px;
  }

  .bannerBox h2 .text {
    font-size: 40px;
  }

  .bannerBox p {
    font-size: 22px;
  }

  .listBox li {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1023px) {
  .bannerBox p {
    font-size: 18px;
  }

  .bannerBox .bottom a {
    padding: 0 20px 0 10px;
  }

  .bannerBox .bottom a::before {
    right: 9px;
  }

  .bannerCntr p {
    font-size: 19px;
  }

  .modal-wrapper {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .bannerBox .banner {
    width: 100%;
  }

  .bannerBox h2 {
    padding: 1% 0 0 0;
  }

  .bannerBox h2 {
    font-size: 30px;
  }

  .bannerBox h2 span {
    font-size: 30px;
    display: inline-block;
  }

  .bannerBox h2 .text {
    font-size: 30px;
    display: inline-block;
  }

  .bannerBox .bottom {
    position: absolute;
    bottom: 20px;
    text-align: center;
  }

  .bannerBox p {
    text-align: center;
    display: block;
    margin-bottom: 10px;
  }

  .listBox .list {
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
  }

  .bannerBox .bottom a {
    float: none;
  }

  .listBox .inner {
    min-height: auto;
  }

  .listBox .read {
    position: relative;
    margin-top: 20px;
    display: inline-block;
  }

  .bannerCntr li {
    width: 100%;
    margin-bottom: 15px;
  }

  .bannerCntr li:last-child {
    margin-bottom: 0;
  }

  .bannerCntr p {
    padding: 5px 10px;
    font-size: 16px;
  }

  .headercol .logo {
    width: 70px;
  }

  .headercol .email {
    display: none;
  }

  .listBox h3 {
    font-size: 18px;
    font-weight: bold;
  }

  .naviBox {
    padding: 10px 0;
    position: relative;
    width: auto;
    float: right;
    background: none;
  }

  .footerBox .copyright {
    text-align: center;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .naviBox .menu {
    display: none;
    padding-top: 15px;
    position: fixed;
    left: 0;
    top: 44px;
    height: 92%;
    z-index: 99;
    width: 100%;
    background: #2a2a2a;
  }

  .naviBox ul {
    padding: 0;
    width: 100%;
  }

  .naviBox li {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    line-height: 36px;
    border-bottom: 1px solid #424141;
  }

  .naviBox li:hover {
    background: #000;
  }

  .naviBox li a {
    color: #fff;
  }

  .naviBox li:hover::after {
    height: 36px;
  }

  .naviBox .linkbar {
    display: block;
  }

  .headercol {
    height: auto;
    padding: 0px;
  }

  .headercol .logo {
    margin: 10px 0;
  }

  .headercol {
    border-bottom: 2px solid #00327a;
  }

  .headercol.fixed {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
  }

  .footerBox .card {
    text-align: center;
    float: none;
  }

  .footerBox ul {
    text-align: center;
  }

  .footerBox li {
    display: inline-block;
    margin-bottom: 10px;
    float: none;
    text-align: center;
  }

  .tophadding h4 {
    font-size: 26px;
    padding-left: 113px;
    background-size: 100px;
    line-height: 55px;
  }

  .tophadding {
    padding: 16px 0;
  }

  .navform .btn-circle {
    width: 25px;
    height: 25px;
    line-height: 24px;
    font-size: 13px;
  }

  .navform p {
    font-size: 12px;
  }

  .colam50 {
    width: 100%;
    padding: 0 10px;
  }

  .frameBox.mh {
    min-height: auto;
  }

  .btn-input {
    font-size: 12px;
    padding: 0 7px;
  }

  .frameBox p {
    font-size: 11px;
  }

  .hedinginfo {
    font-size: 16px;
    line-height: 38px;
    text-align: center;
  }

  .hedinginfo.mar {
    margin: 0 -10px;
  }

  .rightside {
    border: none;
  }

  .rightside .btn-input {
    min-width: 90px;
  }

  .paymentBox li img {
    width: 72px;
  }

  .paymentBox li {
    margin-bottom: 10px;
  }

  .paymentBox .payment {
    padding: 10px 2px;
    margin-right: 0;
  }

  .paymentBox {
    padding: 0px 10px;
  }

  .modal-wrapper {
    width: 90%;
    padding: 10px;
  }

  .modal-wrapper h3 {
    font-size: 18px;
    margin: 0px 0 10px 0;
  }

  .modal-wrapper p {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 15px;
  }

  .modal-wrapper p span {
    font-size: 14px;
  }

  .modal-wrapper .btn-input {
    min-width: 105px;
  }

  .amountColam h1 {
    font-size: 18px;
  }

  .amountColam {
    padding: 20px;
  }

  .servicesBox .leftside {
    width: 100%;
    padding: 0;
  }

  .servicesBox .centerside,
  .servicesBox .rightside {
    width: 100%;
  }

  .welcomeText {
    margin: 15px 0 0 0;
  }

  .welcomeText h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .welcomeText p {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 410px) {
  .bannerBox h2 {
    font-size: 20px;
  }

  .bannerBox h2 span {
    font-size: 20px;
    display: inline-block;
  }

  .bannerBox h2 .text {
    font-size: 20px;
    display: inline-block;
  }

  .bannerBox .bottom {
    bottom: 5px;
  }

  .bannerBox p {
    margin-bottom: 0px;
    font-size: 14px;
  }

  .bannerBox .bottom a {
    line-height: 24px;
  }

  .bannerBox .bottom a::before {
    top: 7px;
  }

  .servicesBox .leftside,
  .servicesBox .centerside,
  .servicesBox .rightside {
    width: 100%;
    padding: 0;
  }
}

.custom-file-input {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.custom-file-input input[type="file"] {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
.error {
  color: red !important;
  font-weight: 500 !important;
  position: absolute;
  font-size: 12px;
}

.paymentContainer p {
  text-decoration: none;
  color: #000;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}

.paymentContainer h2,
.paymentContainer h3 {
  font-size: 26px;
  font-weight: 600;
}

.main-preview .form-group label {
  font-size: 14px;
  font-weight: 600;
}

.main-preview .form-group p {
  font-size: 14px;
}

.react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
  display: none;
}
select.react-datepicker__month-select {

  padding: 7px;
  border: 1px solid #0f46db;
  color: black;
}
select.react-datepicker__year-select {
  padding: 7px;
  border: 1px solid #0f46db;
  color: black;
}


